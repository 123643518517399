<template>
  <div class="shell" id="shell">
    <div class="header">
      <h2 class="title">小淘汰的私生活</h2>
      <h3 class="subtitle">我的时光轴</h3>
    </div>
      <div class="timeline" >
        <div class="item" :data-text="item.title" v-for="item in timeLineList" :key="item.index">
          <div class="content">
            <img :src="item.img" alt="" class="img">
            <h2 class="content-title">{{item.year}}</h2>
            <p class="content-desc">
              {{item.content}}
            </p>
          </div>
        </div>

      </div>

    </div>
</template>

<script>
import constant from "../utils/constant";
export default {
  name: "timeLine",
  data() {
    return {
      timeLineList: [],
      selectors: {
        item: null,
        activeClass: 'item--active',
        img: '.img'
      }
    }
  },
  created() {
    this.getTimeLine()
  },

  methods: {
    getTimeLine() {
      this.$http.get(this.$constant.baseURL + "/webInfo/getTimeLine")
        .then(resp => {
          for (let i = 0; i < resp.length; i++) {
            // resp[i].createTime = resp[i].createTime.substring(0, 10);
            const createTime = new Date(resp[i].createTime);
            const year = createTime.getFullYear();
            let item = {
              year: year,
              time: resp[i].createTime,
              title: resp[i].articleTitle,
              img: resp[i].articleCover,
              content: resp[i].content,
              url: constant.webURL + '/article?' + resp[i].id
            }
            this.timeLineList.push(item);
          }
          this.$nextTick(() => {
            this.selectors.item = this.$el.querySelectorAll('.item');
            this.selectors.item[0].classList.add(this.selectors.activeClass);
            this.$el.style.backgroundImage = `url(${
              this.selectors.item[0].querySelector(this.selectors.img).getAttribute('src')
            })`;
          });
        });
    }

  },
  mounted() {
    window.addEventListener('scroll', () => {
      const itemLength = this.selectors.item.length;
      let max, min;
      const pos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      this.selectors.item.forEach((el, i) => {
        min = el.offsetTop;
        max = el.offsetHeight + el.offsetTop;

        if (i === itemLength - 2 && pos > min + el.offsetHeight / 2) {
          this.selectors.item.forEach(item => item.classList.remove(this.selectors.activeClass));
          this.$el.style.backgroundImage = `url(${
            this.selectors.item[itemLength - 1].querySelector(this.selectors.img).getAttribute('src')
          })`;
          this.selectors.item[itemLength - 1].classList.add(this.selectors.activeClass);
        } else if (pos <= max - 10 && pos >= min) {
          this.$el.style.backgroundImage = `url(${el.querySelector(this.selectors.img).getAttribute('src')})`;
          this.selectors.item.forEach(item => item.classList.remove(this.selectors.activeClass));
          el.classList.add(this.selectors.activeClass);
        }
      });
    });
  },


};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.shell {
  width: 100%;
  position: relative;
  padding: 80px 0;
  transition: 0.3s ease 0s;
  background-attachment: fixed;
  background-size: cover;
}

.shell:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(99, 99, 99, 0.8);
  content: "";
}

.header {
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}

.title {
  color: #fff;
  font-size: 46px;
  font-weight: normal;
  margin: 0;
}

.timeline {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 700px;
  position: relative;
}

.content-title {
  font-weight: normal;
  font-size: 66px;
  margin: -10px 0 0 0;
  transition: 0.4s;
  padding: 0 10px;
  box-sizing: border-box;
  color: #fff;
}

.content-desc {
  margin: 0;
  font-size: 15px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.7);
  line-height: 25px;
}

.timeline:before {
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  content: "";
  background: rgba(255, 255, 255, 0.07);
}

.item {
  padding: 40px 0;
  opacity: 0.3;
  filter: blur(2px);
  transition: 0.5s;
  box-sizing: border-box;
  width: calc(50% - 40px);
  display: flex;
  position: relative;
  transform: translateY(-80px);
}

.item:before {
  content: attr(data-text);
  letter-spacing: 3px;
  width: 100%;
  position: absolute;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  top: 70%;
  margin-top: -5px;
  padding-left: 15px;
  opacity: 0;
  right: calc(-100% - 56px);
  font: 900 20px '';
  letter-spacing: 5px;
}

.item:nth-child(even) {
  align-self: flex-end;
}

.item:nth-child(even):before {
  right: auto;
  text-align: right;
  left: calc(-100% - 56px);
  padding-left: 0;
  border-left: none;
  border-right: 2px solid rgba(255, 255, 255, 0.5);
  padding-right: 15px;
}

.item--active {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0px);
}

.item--active:before {
  top: 50%;
  transition: 0.3s all 0.2s;
  opacity: 1;
}

.item--active .content-title {
  margin: -50px 0 20px 0;
}

.img {
  max-width: 100%;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.4);
}

.subtitle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  letter-spacing: 5px;
  margin: 10px 0 0 0;
  font-weight: normal;
}

.footer {
  padding: 95px 0;
  text-align: center;
}

.footer a {
  color: #999;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .item {
    align-self: baseline !important;
    width: 100%;
    padding: 0 30px 150px 80px;
  }

  .item:before {
    left: 10px !important;
    padding: 0 !important;
    top: 50px;
    text-align: center !important;
    width: 60px;
    border: none !important;
  }

  .item:last-child {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .timeline:before {
    left: 40px;
  }
}
</style>
